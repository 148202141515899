import * as React from "react";
import type { GatsbyBrowser } from "gatsby";
import { WrapRootElement } from "./src/provider";
import Clarity from "@microsoft/clarity";

const projectId = "q5rz4qo61t";
Clarity.init(projectId);
window.Clarity = Clarity;

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
  element,
}) => <WrapRootElement element={element} />;


export const onClientEntry = () => {
  if (process.env.NODE_ENV === "development") {
    console.log("Injecting development script for hover/click tracking...");

    const script = document.createElement("script");
    script.textContent = `
      (function() {
        function handleMouseEnter(event) {
          event.stopPropagation(); // Stop bubbling to parent elements

          // Remove outlines from all other elements
          document.querySelectorAll("[data-element]").forEach(el => {
            el.style.outline = "";
          });

          // Find the nearest element with data-element
          const target = event.target.closest("[data-element]");
          if (!target) return;

          // Apply outline style to the nearest element
          target.style.outline = "2px dashed yellow";

          // Add tabindex dynamically (for keyboard navigation)
          if (!target.hasAttribute("tabindex")) {
            target.setAttribute("tabindex", "0");
          }
        }

        function handleMouseLeave(event) {
          event.stopPropagation();
          const target = event.target.closest("[data-element]");
          if (!target) return;

          // Remove outline when mouse leaves
          target.style.outline = "";
          target.removeAttribute("tabindex");
        }

        function handleClick(event) {
          event.stopPropagation();

          // Find the nearest parent with data-element
          let target = event.target.closest("[data-element]");
          if (!target) return;

          // Traverse up if needed to find the nearest meaningful parent
          const meaningfulParent = target.closest("[data-element]");

          if (meaningfulParent) {
            target = meaningfulParent;
          }

          // Get component details
          const elementType = target.getAttribute("data-element") || "unknown";
          const line = target.getAttribute("data-line") || "unknown";
          const column = target.getAttribute("data-column") || "unknown";

          const sourceFile = target.closest("[data-source-file]")?.getAttribute("data-source-file") || "unknown";

          // Prepare message payload
          const message = {
            type: "chakra-element-click",
            element: elementType,
            line: line,
            column: column,
            sourceFile: sourceFile
          };

          // Send to parent frame (if in an iframe)
          if (window.parent !== window) {
            window.parent.postMessage(message, "*");
          }

          console.log("Sent message to parent frame:", message);
        }

        function addEventListeners() {
          document.querySelectorAll("[data-element]").forEach(el => {
            el.removeEventListener("mouseenter", handleMouseEnter);
            el.removeEventListener("mouseleave", handleMouseLeave);
            el.removeEventListener("click", handleClick);

            el.addEventListener("mouseenter", handleMouseEnter);
            el.addEventListener("mouseleave", handleMouseLeave);
            el.addEventListener("click", handleClick);
          });
        }

        // Run on initial load
        addEventListeners();

        // Re-run when the DOM updates (for SPAs)
        const observer = new MutationObserver(() => {
          addEventListeners();
        });

        observer.observe(document.body, { childList: true, subtree: true });
      })();
    `;

    document.head.appendChild(script);
  }
};
